<template>
  <v-skeleton-loader
    v-bind="$attrs"
    :min-height="height"
    :max-height="height"
    type="list-item-three-line"
  >
    <slot />
  </v-skeleton-loader>
</template>
<script>
export default {
  props: {
    height: {
      type: Number
    }
  },
  name: 'ItemLoaderQuote'
}
</script>